<template>
  <div>
    <div class="main">
      <div class="richBox">
        <div class="richTopImg">
          <img src="@/assets/images/richTopImg.png">
        </div>
        <div style="padding: 0 13px">
          <div class="richTopCMVbox">
            <div v-if="richInfo" class="richTopCMVtotal">
              当前排名:{{ richInfo.myRanking }}
            </div>
            <ul class="richTopCMV">
              <li>
                <h3>待定</h3>
                <p>预计奖励（元）</p>
              </li>
              <li>
                <h3 v-if="richInfo">{{ richInfo.mySalesVolume }}</h3>
                <p>GMV</p>
              </li>
            </ul>
          </div>

          <div class="richListBox">
            <div class="richListTitle">创富排行榜</div>
            <table border="0" class="richTable">
              <tr>
                <th>排名</th>
                <th>区域名称</th>
                <th>区域人数</th>
                <th>GMV</th>
              </tr>
              <tr v-for="(itme, index) in richList" :key="index">
                <td>
                  <div v-if="index < 3" class="ranking">
                    <img :src="imgs[index]">
                  </div>
                  <div v-else>{{ index + 1 }}</div>
                </td>
                <td>{{ itme.companyName }}</td>
                <td>{{ itme.companyNum }}</td>
                <td>{{ itme.sumPrice }}</td>
              </tr>
              <!-- <tr>
                <td>
                  <div class="ranking">
                    <img src="@/assets/images/ranking2.png" />
                  </div>
                </td>
                <td>row 2</td>
                <td>row 2</td>
                <td>row 2</td>
              </tr>
              <tr>
                <td>
                  <div class="ranking">
                    <img src="@/assets/images/ranking3.png" />
                  </div>
                </td>
                <td>row 2</td>
                <td>row 2</td>
                <td>row 2</td>
              </tr>
              <tr>
                <td>4</td>
                <td>row 2</td>
                <td>row 2</td>
                <td>row 2</td>
              </tr> -->
            </table>
            <div class="richListMore" @click="GetMoreList">
              查看更多
              <span class="MoreIocn">
                <img src="@/assets/images/MoreIocn.png">
              </span>
            </div>
          </div>
          <div v-if="getClientInfo()" class="inviteFriends" @click="configShare">邀请好友</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetRichList } from '@/services/activity.js'
import { getClientInfo } from '@/utils/public.js'
import { wxConfigInit } from '@/utils/wx-api'

export default {
  name: 'RichList',
  data() {
    return {
      show: false,
      topBannerData: {
        codeNumber: '',
        image: '',
        title: '',
        desc: ''
      },
      page: 1,
      richInfo: null,
      richList: [],
      imgs: [
        require('@/assets/images/ranking1.png'),
        require('@/assets/images/ranking2.png'),
        require('@/assets/images/ranking3.png')
      ]
    }
  },
  created() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false')
    })
  },
  mounted() {
    window.RNshareDataChange = this.RNshareDataChange
    this.GetRichList(1)
  },
  destroyed() {

  },
  methods: {
    getClientInfo() {
      return getClientInfo()
    },
    // RN端发送分享信息
    RNshareDataChange() {
      // const mid = this.$route.query.mid
      const _url = 'https://web.yuetao.vip/richList?codeNumber=' +
        this.topBannerData.codeNumber
      const _Router = {
        eventName: 'share',
        url: _url,
        image: this.topBannerData.image,
        title: this.topBannerData.title,
        des: this.topBannerData.desc
      }
      this.$store.dispatch('RNrouter', _Router)
    },

    GetMoreList() {
      this.GetRichList(++this.page)
    },
    GetRichList(page) {
      let uid
      if (getClientInfo()) {
        uid = this.$route.query.mid
      } else {
        uid = localStorage.getItem('uid')
      }
      if (uid) {
        const body = {
          mid: uid,
          page: page,
          pageSum: 10
        }
        GetRichList(body).then((res) => {
          if (res.list && res.list.length !== 0) {
            this.richList = [...this.richList, ...res.list]
          }
          this.richInfo = res.myself
          this.topBannerData = res.shareData
        })
      } else {
        this.$store.dispatch('loginIntercept', this.$route.query)
      }
    },
    configShare() {
      // 配置分享
      if (getClientInfo()) {
        this.RNshareDataChange()
      } else {
        const url =
          window.location.href + '?codeNumber=' + this.topBannerData.codeNumber
        const shareInfo = {
          title: this.topBannerData.title, // 分享标题
          desc: this.topBannerData.desc, // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.topBannerData.image // 分享图标
        }
        wxConfigInit(shareInfo)
        // this.$store.dispatch('wxConfigInit', shareInfo)
      }
    }
  }
}
</script>
<style lang="less" scoped>
#app {
  height: 100%;
}

html,
body {
  height: 100%;
}

.richBox {
  height: 100%;
  width: 375px;
  background: #ba2930;
  min-height: 100vh;

  .richTopImg {
    width: 100%;
    height: 189px;
  }

  .richTopCMVbox {
    width: 100%;
    height: 103px;
    background: #fff;
    border-radius: 7px;
    margin-top: 34px;
    position: relative;

    .richTopCMVtotal {
      background: url("../../../assets/images/richTopCMVtotal.png") no-repeat;
      background-size: 100%;
      position: absolute;
      top: -30px;
      width: 270px;
      height: 59px;
      line-height: 63px;
      left: 0;
      right: 0;
      margin: 0 auto;
      font-size: 14px;
      font-family: FZZhengHeiS-B-GB Regular, FZZhengHeiS-B-GB Regular-Regular;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
    }

    .richTopCMV {
      // background: #000;
      width: 100%;
      height: 70px;
      padding-top: 39px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      li {
        width: 50%;
        height: 60px;
        line-height: 24px;

        h3 {
          font-size: 16px;
          font-weight: bold;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  .richListBox {
    width: 100%;
    background: #fff;
    border-radius: 7px;
    margin-top: 15px;
    padding: 10px 10px;

    .richListTitle {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .richTable {
      width: 100%;
      line-height: 40px;

      tr {
        table-layout: fixed;
      }

      th,
      td {
        text-align: center;

        .ranking {
          width: 28px;
          height: 23px;
          margin: 0 auto;
        }
      }
    }
  }

  .richListMore {
    padding: 15px 0;
    color: #999999;
    font-size: 11px;

    .MoreIocn {
      width: 5px;
      height: 9px;
      display: inline-block;
      margin-left: 7px;
    }
  }

  .inviteFriends {
    width: 100%;
    height: 71px;
    margin-top: 20px;
    background: url("../../../assets/images/inviteFriends.png") no-repeat;
    background-size: 100%;
    line-height: 61px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    //   inviteFriends
    //   background: #000;
  }
}
</style>
